import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { ImageSources } from '@snapchat/snap-design-system-marketing';
import { Icon, Picture, Video } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { getImageSourceSettings } from '../../../../components/Image';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import type { ContentfulIdProps } from '../../../../types/contentful';
import { combineImageSources } from '../../../../utils/combineImageSources';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import {
  headingCss,
  heroCss,
  imageContainerCss,
  imageCss,
  scrollContainerCss,
} from './AvalonHomeHero.styles';
import type { AvalonHomeHeroProps } from './avalonHomeHeroQuery';
import { avalonHomeHeroQuery } from './avalonHomeHeroQuery';

const scrollToNextSection = (sectionId: string) => {
  const currentSection = document.getElementById(sectionId);
  const nextSection = currentSection?.nextElementSibling;

  if (nextSection) {
    nextSection.scrollIntoView({ behavior: 'smooth' });
  }
};

export const AvalonHomeHero: FC<ContentfulIdProps> = props => {
  const { data } = useContentfulQuery<AvalonHomeHeroProps, ContentfulIdVariable>(
    avalonHomeHeroQuery,
    { variables: { id: props.id } }
  );

  const { getImageSources } = useContentfulImages();

  if (!data) return <LoadingPlaceholder />;

  const { backgroundMedia, title } = data.avalonHomeHero;

  if (!backgroundMedia.media) return null;

  const sectionId = 'avalon-hero';
  const desktopMedia = backgroundMedia.media;
  const mobileMedia = backgroundMedia.mobileMedia;
  const backgroundMediaType = backgroundMedia.__typename;

  let imgSrcs: ImageSources | undefined;

  if (backgroundMediaType === 'Image') {
    const { desktopSettings, mobileSettings } = getImageSourceSettings({
      desktopHeight: backgroundMedia.media?.height ?? 0,
      mobileHeight: backgroundMedia.mobileMedia?.height ?? 0,
      enableHighDpi: backgroundMedia.enableHighDpi,
      quality: backgroundMedia.quality,
    });

    imgSrcs = combineImageSources({
      desktop: getImageSources(desktopMedia.url, desktopSettings),
      mobile: getImageSources(mobileMedia?.url, mobileSettings),
    });
  }

  return (
    <section id={sectionId} className={cx('avalon-home-hero', heroCss)}>
      {title ? <h1 className={headingCss}>{title}</h1> : null}
      {backgroundMediaType === 'Image' ? (
        <Picture
          altText={desktopMedia.description}
          className={imageContainerCss}
          imgClassName={imageCss}
          imgSrcs={imgSrcs}
        />
      ) : backgroundMediaType === 'Video' ? (
        <Video
          isBackgroundVideo
          source={desktopMedia.url}
          sourceType={desktopMedia.contentType}
          mobileSource={mobileMedia?.url}
          mobileSourceType={mobileMedia?.contentType}
        />
      ) : null}
      <div
        aria-label="Scroll down to the next section"
        className={scrollContainerCss}
        role="button"
        tabIndex={0}
        onClick={() => scrollToNextSection(sectionId)}
      >
        <Icon name="chevron-down" fill="white" size={32} />
      </div>
    </section>
  );
};
