import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import {
  Alignment,
  Picture,
  VerticalAlignment,
  Video,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { CallToAction } from '../../../../components/CallToAction';
import { getImageSourceSettings } from '../../../../components/Image';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { combineImageSources } from '../../../../utils/combineImageSources';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import { fixedPositionBlockQuery } from './FixedPositionBlock.query';
import {
  alignBottomCss,
  alignBottomMobileCss,
  alignMiddleCss,
  alignMiddleMobileCss,
  blockContainerCss,
  blockContentCss,
  blockPositionCss,
  cardContainerCss,
  cardContentCss,
  cardCtasCss,
  imageCss,
  justifyCenterCss,
  justifyRightCss,
} from './FixedPositionBlock.styles';
import type { FixedPositionBlockDataHandlerProps } from './types';

export const FixedPositionBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<FixedPositionBlockDataHandlerProps, ContentfulIdVariable>(
    fixedPositionBlockQuery,
    {
      variables: { id: props.id },
    }
  );

  const { getImageSources } = useContentfulImages();

  if (!data?.fixedPositionBlock) return <LoadingPlaceholder />;

  const { backgroundMedia, body, ctaCollection, desktopAlignment, mobileAlignment, title } =
    data.fixedPositionBlock;

  const desktopMedia = backgroundMedia.media;
  const mobileMedia = backgroundMedia.mobileMedia;
  const backgroundMediaType = backgroundMedia.__typename;

  let imageSrcs;

  if (backgroundMediaType === 'Image') {
    const { desktopSettings, mobileSettings } = getImageSourceSettings({
      desktopHeight: desktopMedia?.height ?? 0,
      mobileHeight: mobileMedia?.height ?? 0,
      enableHighDpi: backgroundMedia.enableHighDpi,
      quality: backgroundMedia.quality,
    });

    imageSrcs = combineImageSources({
      desktop: getImageSources(desktopMedia.url, desktopSettings),
      mobile: getImageSources(mobileMedia?.url, mobileSettings),
    });
  }

  const [desktopVerticalAlignment, desktopHorizontalAlignment] = desktopAlignment.split(' ');

  return (
    <section className={cx('avalon-fixed-position-block', blockContainerCss)}>
      {backgroundMediaType === 'Image' && (
        <Picture altText={desktopMedia.description} imgClassName={imageCss} imgSrcs={imageSrcs} />
      )}
      {backgroundMediaType === 'Video' && (
        <Video
          isBackgroundVideo
          source={desktopMedia.url}
          sourceType={desktopMedia.contentType}
          mobileSource={mobileMedia?.url}
          mobileSourceType={mobileMedia?.contentType}
        />
      )}
      <div
        className={cx(blockPositionCss, {
          [justifyCenterCss]: desktopHorizontalAlignment === Alignment.Center,
          [justifyRightCss]: desktopHorizontalAlignment === Alignment.Right,
          [alignMiddleCss]: desktopVerticalAlignment === VerticalAlignment.Middle,
          [alignBottomCss]: desktopVerticalAlignment === VerticalAlignment.Bottom,
          [alignMiddleMobileCss]: mobileAlignment === VerticalAlignment.Middle,
          [alignBottomMobileCss]: mobileAlignment === VerticalAlignment.Bottom,
        })}
      >
        <div className={blockContentCss}>
          <div className={cardContainerCss}>
            <div className={cardContentCss}>
              <h4>{title}</h4>
              {renderRichText(body)}
            </div>
            <div className={cardCtasCss}>
              {ctaCollection?.items.map(item => (
                <CallToAction key={item.sys.id} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
