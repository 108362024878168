import type { Motif, MotifComponentProps } from '@snapchat/snap-design-system-marketing';
import {
  defaultMotif,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

const headerVars: Partial<MotifComponentProps<MotifComponent.HEADER>> = {
  '--global-header-navigator-item-active-color': m('--primary-v100'),
  '--global-header-navigator-item-hover-color': m('--primary-v100'),
  '--global-header-item-active-color': m('--primary-v100'),
};

const rootVars: Partial<MotifComponentProps<MotifComponent.ROOT>> = {
  '--h1-desktop-font-size': '144px',
  '--h1-desktop-font-line-height': '90%',
  '--h1-desktop-font-weight': '700',
  '--h1-desktop-font-stretch': 'condensed',

  '--h2-desktop-font-size': '90px',
  '--h2-desktop-font-line-height': '100%',
  '--h2-desktop-font-weight': '700',
  '--h2-desktop-font-stretch': 'condensed',

  '--h3-desktop-font-size': '40px',
  '--h3-desktop-font-line-height': '120%',
  '--h3-desktop-font-weight': '600',

  '--h4-desktop-font-size': '28px',
  '--h4-desktop-font-line-height': '120%',

  '--h5-desktop-font-size': '24px',
  '--h5-desktop-font-line-height': '120%',

  '--h6-desktop-font-size': '18px',
  '--h6-desktop-font-line-height': '120%',

  '--annotation-desktop-font-size': '14px',
  '--annotation-desktop-font-weight': '500',
  '--annotation-desktop-font-letter-spacing': '-0.14px',

  '--action-desktop-font-letter-spacing': '0.65px',
  '--action-desktop-font-weight': '600',
  '--action-desktop-font-line-height': '140%',

  '--h1-mobile-font-size': '72px',
  '--h1-mobile-font-line-height': '90%',
  '--h1-mobile-font-weight': '700',
  '--h1-mobile-font-stretch': 'condensed',

  '--h2-mobile-font-size': '48px',
  '--h2-mobile-font-line-height': '100%',
  '--h2-mobile-font-weight': '700',
  '--h2-mobile-font-stretch': 'condensed',

  '--h3-mobile-font-size': '28px',
  '--h3-mobile-font-line-height': '120%',
  '--h3-mobile-font-weight': '600',

  '--h4-mobile-font-size': '22px',
  '--h4-mobile-font-line-height': '120%',

  '--h5-mobile-font-size': '20px',
  '--h5-mobile-font-line-height': '120%',

  '--h6-mobile-font-size': '18px',
  '--h6-mobile-font-line-height': '120%',

  '--annotation-mobile-font-size': '14px',
  '--annotation-mobile-font-weight': '500',
  '--annotation-mobile-font-letter-spacing': '-0.14px',

  '--action-mobile-font-letter-spacing': '0.65px',
  '--action-mobile-font-weight': '600',
  '--action-mobile-font-line-height': '140%',
};

const buttonVars: Partial<MotifComponentProps<MotifComponent.BUTTON>> = {
  '--button-secondary-bg-color': m('--neutral-v700'),
  '--button-secondary-hover-bg-color': m('--neutral-v700'),
  '--button-secondary-border-color': m('--primary-v100'),
  '--button-secondary-hover-border-color': m('--primary-v100'),
  '--button-secondary-fg-color': m('--neutral-v0'),
  '--button-border-width': '2px',
};

/** Motif for the Avalon website */
export const avalonMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Avalon',
  [MotifScheme.DEFAULT]: {
    name: 'Avalon Yellow background.',
    [MotifComponent.ROOT]: rootVars,
    [MotifComponent.HEADER]: headerVars,
    [MotifComponent.BUTTON]: buttonVars,
  },
  [MotifScheme.SECONDARY]: {
    name: 'Avalon Black background.',
    [MotifComponent.ROOT]: rootVars,
    [MotifComponent.HEADER]: headerVars,
    [MotifComponent.BUTTON]: buttonVars,
  },
  [MotifScheme.TERTIARY]: {
    name: 'Avalon White background.',
    [MotifComponent.ROOT]: rootVars,
    [MotifComponent.HEADER]: headerVars,
    [MotifComponent.BUTTON]: buttonVars,
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Avalon Gray background. Glassy Stuff.',
    [MotifComponent.ROOT]: {
      ...rootVars,
      '--bg-color': 'transparent',
      '--bg-image':
        'linear-gradient(130.29deg, rgba(32, 32, 32, 0.6) -15.44%, rgba(44, 43, 43, 0.9) 45.29%, rgba(42, 42, 42, 0.32) 101.37%);',
      '--fg-color': m('--neutral-v0'),
    },
    [MotifComponent.HEADER]: headerVars,
    [MotifComponent.BUTTON]: buttonVars,
    [MotifComponent.CONTENT]: {
      '--content-backdrop-filter': 'blur(8px)',
      '--content-box-shadow': 'inset 0px 12px 12px 0px #00000014',
      '--content-border-width': '1px',
      '--content-border-color': 'rgba(255, 255, 255, 0.2);',
    },
  },
});
