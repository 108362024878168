import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { ImageSources } from '@snapchat/snap-design-system-marketing';
import { Picture, Video } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';

import { getImageSourceSettings } from '../../../../components/Image';
import { combineImageSources } from '../../../../utils/combineImageSources';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { AvalonCard } from '../AvalonCard/AvalonCard';
import { firstMosaicCss, gridCardContainerCss } from './MosaicGridCard.styles';
import type { MosaicGridProps } from './types';

export const MosaicGridCard: FC<MosaicGridProps> = props => {
  const { getImageSources } = useContentfulImages();

  const { media, title, body, textAlignment, mediaDirection, isFirst } = props;

  let imgSrcs: ImageSources | undefined;
  const desktopMedia = media.media;
  const mobileMedia = media.mobileMedia;

  if (media.__typename === 'Image') {
    const { desktopSettings, mobileSettings } = getImageSourceSettings({
      desktopHeight: desktopMedia?.height ?? 0,
      mobileHeight: mobileMedia?.height ?? 0,
      enableHighDpi: media.enableHighDpi,
      quality: media.quality,
    });

    imgSrcs = combineImageSources({
      desktop: getImageSources(desktopMedia.url, desktopSettings),
      mobile: getImageSources(mobileMedia?.url, mobileSettings),
    });
  }

  let mediaElement: ReactNode | undefined;

  if (media.__typename === 'Image') {
    mediaElement = <Picture altText={media.media.description} imgSrcs={imgSrcs} />;
  }

  if (media.__typename === 'Video') {
    mediaElement = (
      <Video
        source={desktopMedia.url}
        sourceType={desktopMedia.contentType}
        mobileSource={mobileMedia?.url}
        mobileSourceType={mobileMedia?.contentType}
      />
    );
  }

  return (
    <section
      className={cx('avalon-mosaic-grid-card', gridCardContainerCss, { [firstMosaicCss]: isFirst })}
    >
      <AvalonCard
        mediaAlignment={mediaDirection}
        textAlignment={textAlignment}
        media={mediaElement}
        title={title}
        body={renderRichText(body)}
      />
    </section>
  );
};
