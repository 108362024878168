import { css } from '@emotion/css';
import { largeDesktopMediaQuery, m } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

// Constrain canvas in case of ultrawide screen
export const outerScrollContainerCss = css`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const innerScrollContainerCss = css`
  height: calc(100vh - var(${totalHeaderHeightCssVar}));
  top: var(${totalHeaderHeightCssVar});
  /* required for container orientation query */
  container-type: size;
`;

export const scrubVideoCss = css`
  position: absolute;
  /* using contain rather than cover to ensure product is fully in frame. */
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const textNodeCss = css`
  position: absolute;
  width: 30%;
  min-width: 100px;
  padding: ${m('--spacing-m')};
  backdrop-filter: blur(8px);
  box-shadow: inset 0px 12px 12px 0px #00000014;
  background-image: linear-gradient(
    130.29deg,
    rgba(32, 32, 32, 0.6) -15.44%,
    rgba(44, 43, 43, 0.9) 45.29%,
    rgba(42, 42, 42, 0.32) 101.37%
  );
  border-width: 1px;
  /* ensure that text nodes are not visible when video is not animating */
  opacity: 0;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: ${m('--spacing-m')};
  color: ${m('--fg-color')};
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};

  ${largeDesktopMediaQuery} {
    width: 25%;
  }
`;

/**
 * Frame text nodes to ensure they are positioned appropriately around the video.
 *
 * - Landscape displays - use full height and width.
 * - Portrait displays - enforce aspect ratio and center the text nodes over the video.
 */
export const textFrameCss = css`
  height: 100%;
  width: 100%;
  /* Center frame over video */
  position: absolute;

  /* Handle portrait oriented displays */
  @container (orientation: portrait) {
    /* enforce expected aspect ratio */
    aspect-ratio: 4 / 3; /* TODO: replace w/ aspect ratio of final asset once we have it */
    width: 100%;
    height: unset;
    /* Center frame over video */
    top: 50%;
    transform: translateY(-50%);
  }

  /* Fallback for browsers that do not support Container queries */
  @media (orientation: portrait) {
    /* enforce expected aspect ratio */
    aspect-ratio: 4 / 3; /* TODO: replace w/ aspect ratio of final asset once we have it */
    width: 100%;
    height: unset;
    /* Center frame over video */
    top: 50%;
    transform: translateY(-50%);
  }
`;
